var render = function render(){
  var _vm$faqs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('v-tabs', {
    attrs: {
      "grow": ""
    }
  }, [_c('v-tab', {
    class: {
      'on': _vm.filter.type == '회원서비스'
    },
    on: {
      "click": function ($event) {
        _vm.filter.type = '회원서비스';
        _vm.search();
      }
    }
  }, [_vm._v("회원서비스")]), _c('v-tab', {
    class: {
      'on': _vm.filter.type == '상품'
    },
    on: {
      "click": function ($event) {
        _vm.filter.type = '상품';
        _vm.search();
      }
    }
  }, [_vm._v("상품")]), _c('v-tab', {
    class: {
      'on': _vm.filter.type == '배송'
    },
    on: {
      "click": function ($event) {
        _vm.filter.type = '배송';
        _vm.search();
      }
    }
  }, [_vm._v("배송")]), _c('v-tab', {
    class: {
      'on': _vm.filter.type == '교환/반품'
    },
    on: {
      "click": function ($event) {
        _vm.filter.type = '교환/반품';
        _vm.search();
      }
    }
  }, [_vm._v("교환/반품")]), _c('v-tab', {
    class: {
      'on': _vm.filter.type == '입금/결제'
    },
    on: {
      "click": function ($event) {
        _vm.filter.type = '입금/결제';
        _vm.search();
      }
    }
  }, [_vm._v("입금/결제")]), _c('v-tab', {
    class: {
      'on': _vm.filter.type == '기타'
    },
    on: {
      "click": function ($event) {
        _vm.filter.type = '기타';
        _vm.search();
      }
    }
  }, [_vm._v("기타")])], 1)], 1), _c('tit-wrap-line', {
    attrs: {
      "noDivider": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-120px",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "label": "제목",
      "item-text": "text",
      "item-value": "value"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 w-sm-80px",
    attrs: {
      "color": "grey"
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.faqs, function (faq) {
    return _c('v-expansion-panel', {
      key: faq
    }, [_c('v-expansion-panel-header', {
      staticClass: "pa-12px px-md-20px py-md-16px border-b"
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--lg font-weight-bold primary--text pr-8px pr-md-12px"
    }, [_vm._v("Q")]), _c('span', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_vm._v(_vm._s(faq.subject))])])]), _c('v-expansion-panel-content', {
      staticClass: "border-b"
    }, [_c('div', {
      staticClass: "d-flex flex-wrap flex-md-nowrap align-center grey lighten-5 pa-12px px-md-20px px-lg-48px"
    }, [_c('span', {
      staticClass: "txt txt--lg font-weight-bold pr-8px pr-md-12px"
    }, [_vm._v("A")]), _c('span', {
      staticClass: "txt txt--sm break-words",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])])], 1);
  }), 1), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !((_vm$faqs = _vm.faqs) !== null && _vm$faqs !== void 0 && _vm$faqs.length),
      expression: "!faqs?.length"
    }],
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm text-center py-20px py-md-30px"
  }, [_vm._v(" 등록된 질문이 없습니다 ")]), _c('v-divider')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }