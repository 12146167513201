<template>
    <client-page>
        <page-section class="page-section--first white--text">
            <v-row align="center" justify-md="space-between">
                <v-col cols="12" md="6" order="2" order-md="1">
                    <div class="text-center text-md-left">
                        <div class="mb-16px mb-md-20px pr-20px pr-lg-180px">
                            <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '54' : '40'" :aspect-ratio="54 / 64" src="/images/sub/about/about-img2.png" class="mx-auto" />
                        </div>
                        <div class="mb-20px mb-md-40px">
                            <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '414' : '300'" :aspect-ratio="414 / 110" src="/images/sub/about/about-tit.png" class="mx-auto ml-md-0" />
                        </div>
                        <div class="font-size-18 font-size-md-24 font-weight-medium line-height-15 white--text">
                            <p>리본바이옴은 단순한 구매를 넘어서 <br class="d-none d-lg-block" />소비자와의 상생을 추구합니다.</p>
                            <p class="mt-10px mt-md-16px">리본바이옴이 고객분들의 제 2의 삶을 <br class="d-none d-lg-block" />개척해주는 도구가 되길 바랍니다.</p>
                        </div>
                        <div class="mt-20px mt-md-6px pl-140px pl-lg-220px">
                            <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '88' : '60'" :aspect-ratio="88 / 60" src="/images/sub/about/about-img3.png" class="mx-auto" />
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="6" order="1" order-md="2">
                    <div class="pb-20px pb-md-0">
                        <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '588' : '400'" :aspect-ratio="588 / 386" src="/images/sub/about/about-img.png" class="mx-auto mr-md-0" />
                    </div>
                </v-col>
            </v-row>
            <div class="page-section__bg" style="background-image:url(/images/sub/about/about-bg.jpg);"></div>
        </page-section>

        <page-section class="primary lighten-5">
            <v-row align="center" justify-md="space-between">
                <v-col cols="12" md="5" xl="7">
                    <div class="pb-20px pb-md-0">
                        <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '588' : '300'" :aspect-ratio="588 / 450" src="/images/sub/about/about-img4.png" class="mx-auto ml-md-0" />
                    </div>
                </v-col>
                <v-col cols="12" md="6" xl="5">
                    <div class="text-center text-md-left">
                        <h2 class="tit tit--lg line-height-135 mb-16px mb-md-20px">
                            모든 사람들의 <span class="primary--text">건강한 삶,<br />보다 나은 삶의 질</span>을<br />선도하는 기업
                        </h2>
                        <div class="txt txt--lg font-weight-medium line-height-15">
                            <p>좋은 제품으로 새로 태어난다는 리본바이옴의 이념에 따라 <br class="d-none d-lg-block" />고객분들께 항상 좋은 제품을 제공함을 지향합니다.</p>
                            <p>리본바이옴은 단순히 회사의 이익이 아닌 안정적인 분배를 통해 <br class="d-none d-lg-block" />고객의 제 2의 삶을 새롭게 탄생하도록 만드는 보상플랜을 <br class="d-none d-lg-block" />제공합니다.</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <template #tit>
                <span class="line-height-135">리본바이옴은 <span class="primary--text">최고의 품질</span>로<br />여러분 곁에 함께하겠습니다.</span>
            </template>
            <template #txt>
                <span class="line-height-15">리본바이옴은 현대인의 건강한 삶을 지향하는 목표와 함께, 최고의 제품을 위해 끊임없는 연구개발에 전념합니다. <br class="d-none d-lg-block" />항상 최고의 품질로 여러분 곁에 가까이 다가갈 것을 약속드립니다.</span>
            </template>
            <div class="about-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide2.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide3.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide4.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide5.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide6.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide7.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide8.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide9.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide10.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide11.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide12.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide13.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <swiper-slide>
                        <v-card rounded="">
                            <div class="about-slide__img" style="background-image:url('/images/sub/about/about-slide14.jpg')"></div>
                        </v-card>
                    </swiper-slide>
                    <div class="swiper-control" slot="pagination">
                        <div class="swiper-button-prev" slot="button-prev"><v-icon>mdi-chevron-left</v-icon></div>
                        <div class="swiper-button-next" slot="button-next"><v-icon>mdi-chevron-right</v-icon></div>
                    </div>
                </swiper>
            </div>
        </page-section>

        <page-section class="grey lighten-5">
            <v-row align="center" justify-md="space-between">
                <v-col cols="12" md="5" xl="7">
                    <div class="pb-20px pb-md-0">
                        <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '588' : '300'" :aspect-ratio="588 / 400" src="/images/sub/about/about-img5.png" class="mx-auto ml-md-0" />
                    </div>
                </v-col>
                <v-col cols="12" md="6" xl="5">
                    <div class="text-center text-md-left">
                        <h2 class="tit tit--lg line-height-135 mb-16px mb-md-20px">리본바이옴<br /><span class="primary--text">N잡 수익 플랫폼</span></h2>
                        <div class="txt txt--lg font-weight-medium line-height-15">소비자가 판매자가 되는 새로운 패러다임으로 국내 최고의 <br class="d-none d-lg-block" />고퀄리티·가성비 상품들을 구매 및 홍보함으로써 정회원에게 <br class="d-none d-lg-block" />수입증대에 효과적인 파트너스 커미션(보너스)을 제공합니다.</div>
                        <div class="btn-wrap btn-wrap--lg">
                            <v-btn x-large color="primary" download="[(주)리본바이옴]방문판매 보상플랜_20230403" href="/file/compensation-plan.pdf">리본바이옴 보상플랜 다운로드</v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </page-section>

        <page-section class="white--text">
            <v-row align="center" justify-md="space-between">
                <v-col cols="12" md="6" xl="5" order="2" order-md="1">
                    <div class="text-center text-md-left">
                        <h2 class="tit tit--lg line-height-135 mb-16px mb-md-20px">메타버스로 체험하는<br /><span class="primary--text">최고의 제품</span></h2>
                        <div class="txt txt--lg font-weight-medium line-height-15 white--text">리본바이옴 메타버스는 사진으로만 볼 수 있는 온라인 쇼핑의 <br class="d-none d-lg-block" />단점을 대체할 수 있습니다. 저희가 마련한 전시관을 통해 <br class="d-none d-lg-block" />제품을 직접 확인하고 구매하세요.</div>
                    </div>
                </v-col>
                <v-col cols="12" md="5" xl="7" order="1" order-md="2">
                    <div class="pb-20px pb-md-0">
                        <v-img :max-width="$vuetify.breakpoint.mdAndUp ? '588' : '300'" :aspect-ratio="588 / 640" src="/images/sub/about/about-img6.png" class="mx-auto mr-md-0" />
                    </div>
                </v-col>
            </v-row>
            <div class="page-section__bg" style="background-image:url(/images/sub/about/about-bg2.jpg);"></div>
        </page-section>

        <page-section class="page-section--last">
            <template #titContents>
                <div class="tit-wrap text-center">
                    <p class="txt txt--lg font-weight-medium line-height-135 mb-10px mb-md-20px">홍보 하나로 수익을 창출하는 가장 쉬운 플랫폼, 리본바이옴</p>
                    <h2 class="tit tit--lg">
                        <span class="line-height-135">지금 <span class="primary--text">바로 시작</span>하세요!</span>
                    </h2>
                </div>
            </template>

            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn outlined color="primary" to="/join" class="v-size--xx-large w-100 min-w-sm-200px">회원가입</v-btn>
                        </v-col>
                        <v-col cols="6" sm="auto">
                            <v-btn color="primary" to="/login" class="v-size--xx-large w-100 min-w-sm-200px">정회원 전환</v-btn>
                        </v-col>
                    </v-row>
                    <p class="txt txt--xs text-center mt-20px mt-md-24px">*자세한 수익창출 플랫폼 및 보상플랜은 정회원 전환 후 “마이오피스”에서 확인하실 수 있습니다.</p>
                </template>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <template #containerImmersive>
                    <v-card outlined tile max-width="790" class="mx-auto">
                        <div class="pa-20px pa-md-40px">
                            <v-row align="center" justify="center" class="row--xl">
                                <v-col cols="auto">
                                    <v-img max-width="144" :aspect-ratio="144 / 56" src="/images/logo.svg" />
                                </v-col>
                                <v-col cols="auto" align-self="stretch" class="d-none d-md-block">
                                    <v-divider vertical class="h-100" />
                                </v-col>
                                <v-col cols="auto">
                                    <p class="txt txt--xs txt--dark font-weight-medium"><span class="primary--text">리본바이옴은 방문판매업으로 정식 신고</span>되어 있으며, <br class="d-none d-md-block" />방문 판매 등에 관한 법률 제15조 2항에 해당하는 경우 <br class="d-none d-md-block" />정회원으로 전환할 수 없습니다.</p>
                                </v-col>
                            </v-row>
                        </div>
                        <v-expansion-panels flat>
                            <v-expansion-panel class="grey lighten-5">
                                <v-expansion-panel-header>
                                    <div class="txt txt--xs txt--dark font-weight-medium">방문 판매 등에 관한 법률 제15조 2항</div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div class="txt txt--xs">
                                        1. 국가공무원, 지방공무원, 교육공무원 및 「사립학교법」에 따른 교원(「고등교육법」 제14조제2항에 따른 강사를 포함한다)<br/>
                                        2. 미성년자. 다만, 제4호 또는 제5호에 해당하지 아니하는 법정대리인의 동의를 받은 경우는 제외한다.<br/>
                                        3. 법인<br/>
                                        4. 다단계판매업자의 지배주주 또는 임직원<br/>
                                        5. 제49조에 따른 시정조치를 2회 이상 받은 자. 다만, 마지막 시정조치에 대한 이행을 완료한 날부터 3년이 지난 자는 제외한다.<br/>
                                        6. 이 법을 위반하여 징역의 실형을 선고받고 그 집행이 종료되거나(집행이 종료된 것으로 보는 경우를 포함한다) 집행이 면제된 날부터 5년이 지나지 아니한 자<br/>
                                        7. 이 법을 위반하여 형의 집행유예를 선고받고 그 유예기간 중에 있는 자
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </template>
            </page-section>
            <div class="page-section__bg" style="background-image:url(/images/sub/about/about-bg3.jpg);"></div>
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,

        ClientPage,
        PageSection,
    },
    data() {
        return {
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: false,
                spaceBetween: 24,
                slidesPerView: 2,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                navigation: {
                    nextEl: ".about-slide .swiper-button-next",
                    prevEl: ".about-slide .swiper-button-prev",
                },
                breakpoints: {
                    576: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                },
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>

<style lang="scss" scoped>
.about-slide {
    &__img {
        width: 100%;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    &:hover {
        ::v-deep {
            .swiper-control {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    ::v-deep {
        .swiper-control {
            bottom: 50%;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
