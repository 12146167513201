<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">자주묻는 질문</template>
            <!-- 게시판 리스트: FAQ 스킨 -->
            <faq-list code="faq"></faq-list>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import FaqList from "@/components/client/center/faqs/faq-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        FaqList,
    },
};
</script>
