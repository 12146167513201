var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-tab"
  }, [_c('ul', {
    staticClass: "tab-list tab-list--block"
  }, _vm._l(_vm.navItems, function (_ref) {
    var text = _ref.text,
      to = _ref.to,
      isOn = _ref.isOn;
    return _c('li', {
      key: to,
      class: {
        on: isOn
      }
    }, [_c('router-link', {
      staticClass: "button",
      attrs: {
        "to": to
      }
    }, [_vm._v(_vm._s(text))])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }