var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first white--text"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "text-center text-md-left"
  }, [_c('div', {
    staticClass: "mb-16px mb-md-20px pr-20px pr-lg-180px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '54' : '40',
      "aspect-ratio": 54 / 64,
      "src": "/images/sub/about/about-img2.png"
    }
  })], 1), _c('div', {
    staticClass: "mb-20px mb-md-40px"
  }, [_c('v-img', {
    staticClass: "mx-auto ml-md-0",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '414' : '300',
      "aspect-ratio": 414 / 110,
      "src": "/images/sub/about/about-tit.png"
    }
  })], 1), _c('div', {
    staticClass: "font-size-18 font-size-md-24 font-weight-medium line-height-15 white--text"
  }, [_c('p', [_vm._v("리본바이옴은 단순한 구매를 넘어서 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("소비자와의 상생을 추구합니다.")]), _c('p', {
    staticClass: "mt-10px mt-md-16px"
  }, [_vm._v("리본바이옴이 고객분들의 제 2의 삶을 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("개척해주는 도구가 되길 바랍니다.")])]), _c('div', {
    staticClass: "mt-20px mt-md-6px pl-140px pl-lg-220px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '88' : '60',
      "aspect-ratio": 88 / 60,
      "src": "/images/sub/about/about-img3.png"
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "pb-20px pb-md-0"
  }, [_c('v-img', {
    staticClass: "mx-auto mr-md-0",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '588' : '400',
      "aspect-ratio": 588 / 386,
      "src": "/images/sub/about/about-img.png"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "page-section__bg",
    staticStyle: {
      "background-image": "url(/images/sub/about/about-bg.jpg)"
    }
  })], 1), _c('page-section', {
    staticClass: "primary lighten-5"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "7"
    }
  }, [_c('div', {
    staticClass: "pb-20px pb-md-0"
  }, [_c('v-img', {
    staticClass: "mx-auto ml-md-0",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '588' : '300',
      "aspect-ratio": 588 / 450,
      "src": "/images/sub/about/about-img4.png"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "5"
    }
  }, [_c('div', {
    staticClass: "text-center text-md-left"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-135 mb-16px mb-md-20px"
  }, [_vm._v(" 모든 사람들의 "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("건강한 삶,"), _c('br'), _vm._v("보다 나은 삶의 질")]), _vm._v("을"), _c('br'), _vm._v("선도하는 기업 ")]), _c('div', {
    staticClass: "txt txt--lg font-weight-medium line-height-15"
  }, [_c('p', [_vm._v("좋은 제품으로 새로 태어난다는 리본바이옴의 이념에 따라 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("고객분들께 항상 좋은 제품을 제공함을 지향합니다.")]), _c('p', [_vm._v("리본바이옴은 단순히 회사의 이익이 아닌 안정적인 분배를 통해 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("고객의 제 2의 삶을 새롭게 탄생하도록 만드는 보상플랜을 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("제공합니다.")])])])])], 1)], 1), _c('page-section', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('span', {
          staticClass: "line-height-135"
        }, [_vm._v("리본바이옴은 "), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("최고의 품질")]), _vm._v("로"), _c('br'), _vm._v("여러분 곁에 함께하겠습니다.")])];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_c('span', {
          staticClass: "line-height-15"
        }, [_vm._v("리본바이옴은 현대인의 건강한 삶을 지향하는 목표와 함께, 최고의 제품을 위해 끊임없는 연구개발에 전념합니다. "), _c('br', {
          staticClass: "d-none d-lg-block"
        }), _vm._v("항상 최고의 품질로 여러분 곁에 가까이 다가갈 것을 약속드립니다.")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "about-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide2.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide3.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide4.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide5.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide6.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide7.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide8.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide9.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide10.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide11.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide12.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide13.jpg')"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    attrs: {
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "about-slide__img",
    staticStyle: {
      "background-image": "url('/images/sub/about/about-slide14.jpg')"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 1)], 1)]), _c('page-section', {
    staticClass: "grey lighten-5"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "7"
    }
  }, [_c('div', {
    staticClass: "pb-20px pb-md-0"
  }, [_c('v-img', {
    staticClass: "mx-auto ml-md-0",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '588' : '300',
      "aspect-ratio": 588 / 400,
      "src": "/images/sub/about/about-img5.png"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "5"
    }
  }, [_c('div', {
    staticClass: "text-center text-md-left"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-135 mb-16px mb-md-20px"
  }, [_vm._v("리본바이옴"), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("N잡 수익 플랫폼")])]), _c('div', {
    staticClass: "txt txt--lg font-weight-medium line-height-15"
  }, [_vm._v("소비자가 판매자가 되는 새로운 패러다임으로 국내 최고의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("고퀄리티·가성비 상품들을 구매 및 홍보함으로써 정회원에게 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("수입증대에 효과적인 파트너스 커미션(보너스)을 제공합니다.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "color": "primary",
      "download": "[(주)리본바이옴]방문판매 보상플랜_20230403",
      "href": "/file/compensation-plan.pdf"
    }
  }, [_vm._v("리본바이옴 보상플랜 다운로드")])], 1)])])], 1)], 1), _c('page-section', {
    staticClass: "white--text"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify-md": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "5",
      "order": "2",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "text-center text-md-left"
  }, [_c('h2', {
    staticClass: "tit tit--lg line-height-135 mb-16px mb-md-20px"
  }, [_vm._v("메타버스로 체험하는"), _c('br'), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("최고의 제품")])]), _c('div', {
    staticClass: "txt txt--lg font-weight-medium line-height-15 white--text"
  }, [_vm._v("리본바이옴 메타버스는 사진으로만 볼 수 있는 온라인 쇼핑의 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("단점을 대체할 수 있습니다. 저희가 마련한 전시관을 통해 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v("제품을 직접 확인하고 구매하세요.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "7",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "pb-20px pb-md-0"
  }, [_c('v-img', {
    staticClass: "mx-auto mr-md-0",
    attrs: {
      "max-width": _vm.$vuetify.breakpoint.mdAndUp ? '588' : '300',
      "aspect-ratio": 588 / 640,
      "src": "/images/sub/about/about-img6.png"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "page-section__bg",
    staticStyle: {
      "background-image": "url(/images/sub/about/about-bg2.jpg)"
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--last",
    scopedSlots: _vm._u([{
      key: "titContents",
      fn: function () {
        return [_c('div', {
          staticClass: "tit-wrap text-center"
        }, [_c('p', {
          staticClass: "txt txt--lg font-weight-medium line-height-135 mb-10px mb-md-20px"
        }, [_vm._v("홍보 하나로 수익을 창출하는 가장 쉬운 플랫폼, 리본바이옴")]), _c('h2', {
          staticClass: "tit tit--lg"
        }, [_c('span', {
          staticClass: "line-height-135"
        }, [_vm._v("지금 "), _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("바로 시작")]), _vm._v("하세요!")])])])];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--sm py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "outlined": "",
            "color": "primary",
            "to": "/join"
          }
        }, [_vm._v("회원가입")])], 1), _c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "primary",
            "to": "/login"
          }
        }, [_vm._v("정회원 전환")])], 1)], 1), _c('p', {
          staticClass: "txt txt--xs text-center mt-20px mt-md-24px"
        }, [_vm._v("*자세한 수익창출 플랫폼 및 보상플랜은 정회원 전환 후 “마이오피스”에서 확인하실 수 있습니다.")])];
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--sm pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('v-card', {
          staticClass: "mx-auto",
          attrs: {
            "outlined": "",
            "tile": "",
            "max-width": "790"
          }
        }, [_c('div', {
          staticClass: "pa-20px pa-md-40px"
        }, [_c('v-row', {
          staticClass: "row--xl",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-img', {
          attrs: {
            "max-width": "144",
            "aspect-ratio": 144 / 56,
            "src": "/images/logo.svg"
          }
        })], 1), _c('v-col', {
          staticClass: "d-none d-md-block",
          attrs: {
            "cols": "auto",
            "align-self": "stretch"
          }
        }, [_c('v-divider', {
          staticClass: "h-100",
          attrs: {
            "vertical": ""
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('p', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("리본바이옴은 방문판매업으로 정식 신고")]), _vm._v("되어 있으며, "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("방문 판매 등에 관한 법률 제15조 2항에 해당하는 경우 "), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v("정회원으로 전환할 수 없습니다.")])])], 1)], 1), _c('v-expansion-panels', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-expansion-panel', {
          staticClass: "grey lighten-5"
        }, [_c('v-expansion-panel-header', [_c('div', {
          staticClass: "txt txt--xs txt--dark font-weight-medium"
        }, [_vm._v("방문 판매 등에 관한 법률 제15조 2항")])]), _c('v-expansion-panel-content', [_c('div', {
          staticClass: "txt txt--xs"
        }, [_vm._v(" 1. 국가공무원, 지방공무원, 교육공무원 및 「사립학교법」에 따른 교원(「고등교육법」 제14조제2항에 따른 강사를 포함한다)"), _c('br'), _vm._v(" 2. 미성년자. 다만, 제4호 또는 제5호에 해당하지 아니하는 법정대리인의 동의를 받은 경우는 제외한다."), _c('br'), _vm._v(" 3. 법인"), _c('br'), _vm._v(" 4. 다단계판매업자의 지배주주 또는 임직원"), _c('br'), _vm._v(" 5. 제49조에 따른 시정조치를 2회 이상 받은 자. 다만, 마지막 시정조치에 대한 이행을 완료한 날부터 3년이 지난 자는 제외한다."), _c('br'), _vm._v(" 6. 이 법을 위반하여 징역의 실형을 선고받고 그 집행이 종료되거나(집행이 종료된 것으로 보는 경우를 포함한다) 집행이 면제된 날부터 5년이 지나지 아니한 자"), _c('br'), _vm._v(" 7. 이 법을 위반하여 형의 집행유예를 선고받고 그 유예기간 중에 있는 자 ")])])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "page-section__bg",
    staticStyle: {
      "background-image": "url(/images/sub/about/about-bg3.jpg)"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }