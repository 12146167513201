<template>
    <div class="center-tab">
        <ul class="tab-list tab-list--block">
            <li v-for="{ text, to, isOn } in navItems" :key="to" :class="{ on: isOn }">
                <router-link class="button" :to="to">{{ text }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
const items = [
    // { text: "회사소개", to: "/center/about" },
    { text: "FAQ", to: "/center/faq" },
    { text: "공지사항", to: "/center/notice" },
    { text: "개인정보처리방침", to: "/center/privacy-policy" },
    { text: "이용약관", to: "/center/terms" },
    // { text: "이메일무단수집거부", to: "/center/email-policy" },
];

export default {
    computed: {
        navItems() {
            this.$route.path;

            return items.map((item) => (this.$route.path.includes(item.to) ? { ...item, isOn: true } : item));
        },
    },
};
</script>

<style></style>
