<template>
    <div>
        <div class="tabs-wrap">
            <v-tabs grow>
                <v-tab :class="{ 'on': filter.type == '회원서비스' }" @click="filter.type='회원서비스'; search();">회원서비스</v-tab>
                <v-tab :class="{ 'on': filter.type == '상품' }" @click="filter.type='상품'; search();">상품</v-tab>
                <v-tab :class="{ 'on': filter.type == '배송' }" @click="filter.type='배송'; search()">배송</v-tab>
                <v-tab :class="{ 'on': filter.type == '교환/반품' }" @click="filter.type='교환/반품'; search();">교환/반품</v-tab>
                <v-tab :class="{ 'on': filter.type == '입금/결제' }" @click="filter.type='입금/결제'; search();">입금/결제</v-tab>
                <v-tab :class="{ 'on': filter.type == '기타' }" @click="filter.type='기타'; search();">기타</v-tab>
            </v-tabs>
        </div>

        <tit-wrap-line noDivider>
            <v-row align="center" class="row--xs">
                <v-col cols="auto">
                    <v-select dense outlined hide-details label="제목" item-text="text" item-value="value" class="w-120px" />
                </v-col>
                <v-col cols="">
                    <v-text-field dense outlined hide-details placeholder="검색어를 입력하세요" />
                </v-col>
                <v-col cols="12" sm="auto">
                    <v-btn color="grey" class="w-100 w-sm-80px">검색</v-btn>
                </v-col>
            </v-row>
        </tit-wrap-line>

        <v-divider style="border-width:2px 0 0;" class="grey darken-4" />
        <v-expansion-panels flat>
            <v-expansion-panel v-for="faq in faqs" :key="faq">
                <v-expansion-panel-header class="pa-12px px-md-20px py-md-16px border-b">
                    <div class="d-flex align-center">
                        <span class="txt txt--lg font-weight-bold primary--text pr-8px pr-md-12px">Q</span>
                        <span class="txt txt--sm txt--dark font-weight-medium">{{ faq.subject }}</span>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="border-b">
                    <div class="d-flex flex-wrap flex-md-nowrap align-center grey lighten-5 pa-12px px-md-20px px-lg-48px">
                        <span class="txt txt--lg font-weight-bold pr-8px pr-md-12px">A</span>
                        <span class="txt txt--sm break-words" v-html="faq.content.replace(/\n/g, '<br>')" />
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
            <v-fade-transition leave-absolute>
                <v-sheet v-show="!faqs?.length" class="text-center">
                    <div class="txt txt--sm text-center py-20px py-md-30px">
                        등록된 질문이 없습니다
                    </div>
                    <v-divider />
                </v-sheet>
            </v-fade-transition>

        <!-- <div class="board-container">
            <ul class="tab-list tab-list--tooth">
                <li :class="{ 'on': filter.type == '회원서비스' }"><a class="button" href="#" @click="filter.type='회원서비스'; search();">회원서비스</a></li>
                <li :class="{ 'on': filter.type == '상품' }"><a class="button" href="#" @click="filter.type='상품'; search();">상품</a></li>
                <li :class="{ 'on': filter.type == '배송' }"><a class="button" href="#" @click="filter.type='배송'; search()">배송</a></li>
                <li :class="{ 'on': filter.type == '교환/반품' }"><a class="button" href="#" @click="filter.type='교환/반품'; search();">교환/반품</a></li>
                <li :class="{ 'on': filter.type == '입금/결제' }"><a class="button" href="#" @click="filter.type='입금/결제'; search();">입금/결제</a></li>
                <li :class="{ 'on': filter.type == '기타' }"><a class="button" href="#" @click="filter.type='기타'; search();">기타</a></li>
            </ul>
            <div role="table" class="board-table board-table--faq">
                <div v-for="faq in faqs" role="rowgroup">
                    <div role="row" class="question" data-idx="1" :data-answer="faq.opened ? 'opened' : 'closed'" @click="faqs.forEach((item) => $set(item, 'opened', item == faq ? !faq.opened : false));">
                        <div role="cell">
                            <div class="tit"><a role="button"><i class="icon">Q</i>{{ faq.subject }}</a></div>
                        </div>
                    </div>
                    <div role="row" class="answer" data-idx-answer="1">
                        <div role="cell">
                            <div class="answer">
                                <div class="tit"><i class="icon">A</i>자주 묻는 질문에 대한 답입니다.</div>
                                <p class="pr" v-html="faq.content.replace(/\n/g,'<br>')"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import api from "@/api";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

export default {
    components: {
        TitWrapLine,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            filter: {
                type: "회원서비스",
            },
            faqs: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search() {
            try {
                var { summary, faqs } = await api.v1.center.faqs.getFaqs({
                    params: this.filter,
                });

                this.faqs = faqs;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap{
    padding: 0;
}
</style>