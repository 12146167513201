var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("공지사항")]), !_vm.$route.params._notification ? _c('notification-list') : _c('notification-view')], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }